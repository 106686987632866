@import "~bootstrap/dist/css/bootstrap.css";
@import '~font-awesome/css/font-awesome.css';
.wrapper {
    display: flex;
    align-items: stretch;
}

.well {
    -webkit-box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
    -moz-box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
    box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
}

.button-area {
    padding-top: 10px;
    padding-bottom: 5px;
}

#sidebarCollapse.menu-collapsed{
    margin-left: 2px;
}

#sidebarCollapse .glyphicon{
    /* Make the button flip */
    -ms-transform: none;
    -webkit-transform: none; 
    transform: none;
    /* Adjust the margin during the flip because it is a little off. */
    margin-left: 1px;
    margin-top: 4px;
}

#sidebarCollapse .glyphicon.isActive{
    /* Make the button flip */
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg); 
    transform: rotate(180deg);
    /* Adjust the margin during the flip because it is a little off. */
    margin-left: 1px;
    margin-top: 5px;
}

#sidebarCollapse.btn{
    padding: 0px 0px!important;
    font-size: 1em!important;
}

#sidebarCollapse{
    margin: 155px 0px 0px 225px;
    left: 0px;
    top: 2px;
    height: 47px !important;
    width: 47px !important;
    position: absolute;
    z-index: 1020;
    /* Make the button a circle. */
    border-radius: 50%;
    outline: none;
    background-color: #E19E2A;
    color: white;
    /* Box shadow */
    -webkit-box-shadow: 2px 2px 5px 0px rgba(122,122,122,1);
    -moz-box-shadow: 2px 2px 5px 0px rgba(122,122,122,1);
    box-shadow: 2px 2px 5px 0px rgba(122,122,122,1);
    /* transition-timing-function: ease-in-out; */
    transition: margin 400ms, scale 400ms, background-color 300ms, -webkit-box-shadow 300ms, -moz-box-shadow 300ms, box-shadow 300ms;
}

#sidebarCollapse.hamburgerTransition{
    transition-timing-function: ease-in-out;
    transition: all 350ms;
    transition-delay: 0ms;
}

#sidebarCollapse:hover{
    /* Transform the button on hover.  Scale it up some. */
    -ms-transform: scale(1.05, 1.05);
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
}

#sidebarCollapse:active{
    /* Transform the button on hover.  Scale it up some. */
    -ms-transform: scale(0.9, 0.9);
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    background-color: #edc47e;
    /* Remove box shadow so it appears the button is pushed down. */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

#sidebarCollapse .glyphicon{
    font-size: 2.2em;
}

#sidebarCollapse .glyphicon.hamburgerTransition{
    transition-timing-function: ease-in-out;
    transition: all 400ms;
    transition-delay: 0ms;
}

/* Increase the hamburger button clickable area. */
#sidebarCollapse::before{
    content: '';
    position: absolute;
    top: -5%;
    left: -5%;
    right: -5%;
    bottom: -5%;
}

#sidebar {
    -webkit-box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
    -moz-box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
    box-shadow: 2px 2px 10px 0px rgba(122,122,122,1);
}

#sidebar.active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

a[data-toggle="collapse"] {
    position: relative;
}


body {
    background: #fafafa;
    width: 100%;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 350ms;
}

#sidebar {   
    background: #033663;
    color: #fff;
    transition: all 350ms;
}

#sidebar .sidebar-header {
    padding: 5px 20px 5px 20px;   /* padding order is: top right bottom left */
}

#sidebar ul.components {
    padding: 0px;
    border-bottom: 1px solid #47748b;
    border-top: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #336699;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #336699;
}

#lien-waiver-navbar-collapse {
    margin-top: -30px;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

@media (min-width: 1200px) {
    .pull-right-lg {
        float: right !important;
    }
    .pull-left-lg {
        float: left !important;
    }
}

@media (min-width: 992px) {
    .pull-right-md {
        float: right !important;
    }
    .pull-left-md {
        float: left !important;
    }
}

@media (min-width: 768px) {
    .pull-right-sm {
        float: right !important;
    }
    .pull-left-sm {
        float: left !important;
    }
}
